<script>
import { getAction, putAction } from '@/command/netTool'
import DetailFormGroup from '../../../components/DetailFormGroup'
import orderConfig from '@/orderConfig'
import api from '@/command/api'
export default {
  name: 'verification',
  data() {
    return {
      formData: {
        verificationCode: ''
      }
    }
  },
  methods: {
    onSubmit() {
      const { verificationCode } = this.formData
      if (!verificationCode) {
        this.$message.error('请输入核销码')
      } else {
        getAction(`/api/order/hx/detail?id=${verificationCode}`).then(e => {
          orderConfig.completedCancel({
            data: e.data,
            params: { data: e.data, type: 'verification' },
            success: ({ data, setHidden }) => {
              let arr = data.userCodeVOList ? data.userCodeVOList.filter(e => e.status == '0') : []
              if (arr.length == 0) {
                this.$message.warning('无可核销券码！')
                return false
              }

              let brr = []
              let crr = []
              if (data.returnNum - 1 > 0) {
                brr = arr.filter(e => e.code != verificationCode)
                brr.forEach((r, i) => {
                  if (i + 1 <= data.returnNum - 1) {
                    crr.push(r.id)
                  }
                })
              }
              let dq = (
                arr.find(r => r.code == verificationCode) || {
                  id: verificationCode
                }
              ).id
              let drr = [dq, ...crr]

              api.order.hx
                .call(this, {
                  url: '/order/hx',
                  params: { idList: drr },
                  isPost: false
                })
                .then(setHidden)
            },
            executeFun: [
              // 表格
              orderConfig.tableList,
              // 优惠券
              //   orderConfig.coupons,
              // 入住信息
              e.data.fromType == '3' && orderConfig.checkInfo,
              // 基础信息,
              orderConfig.base,
              // 记录
              orderConfig.record
            ].filter(e => e)
          })
        })
      }
    },
    getForm1() {
      return {
        title: '商品核销',
        type: 'cardForm',
        data: [
          {
            type: 'row',
            cols: 24,
            gutter: 10,
            name: '核销码',
            children: [
              {
                type: 'input',
                cols: 12,
                key: 'verificationCode',
                label: '核销码'
              },
              {
                label: '查询',
                type: 'button',
                cols: 2,
                props: {
                  type: 'primary',
                  on: {
                    click: this.onSubmit
                  }
                }
              }
            ]
          }
        ]
      }
    }
  },
  render() {
    return <DetailFormGroup data={[this.getForm1()]} form={this.formData} />
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
